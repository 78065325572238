<template>
  <div class="home">
    <el-container style="height: 100vh; border: 1px solid #eee">
      <el-container>
        <!-- 页头开始 -->
        <el-header class="p-el-header">
            <div class="logo-title">
                <img src="../../assets/imgs/logo_h_black.png" alt="" />
            </div>
            <!-- <el-button type="primary" @click="logoutAction">退出登录</el-button> -->
            <div class="user-div">
            <img src="../../assets/imgs/default.png" class="button-style2" />

            <el-dropdown size="medium" trigger="click" placement="bottom">
              <div style="cursor: pointer">
                <span style="font-size: 14px;color:#fff;">{{nickName}}</span>
                <span style="margin-left: 15px; font-size: 14px; color: #999"
                  ><el-icon :size="12" :color="'#fff'">
              <ArrowDown/>
            </el-icon></span>
              </div>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="logoutAction">
                    <div class="out-btn">
                        <el-icon :size="16" :color="'#000'">
                            <SwitchButton />
                        </el-icon>
                        <p>退出登录</p>
                    </div>
                    
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-header>
        <!-- 页头结束 -->

        <!-- 这里是主体部分 -->
        <el-main class="page-main">
            <div class="page-wrap">
                <div class="brand-title">
                    <div class="header-icon" :class="{pointer:isBack}" @click="backPreAction">
                        <el-icon :size="25" :color="'#000'" v-if='isBack'>
                            <ArrowLeft />
                        </el-icon>
                         <p class="page-title">{{pageTitle}}</p>
                    </div>
                   
                </div>
                <router-view />
            </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import {
    ref,
    onMounted,
    computed,
    getCurrentInstance,
    watchEffect
  } from "vue";
  import menu from "../../assets/js/menu";
  import {
    useRoute,
    useRouter
  } from "vue-router";
  import LS from '../../assets/js/ls';
  import {useStore} from "vuex";
  import { ElMessageBox,ElMessage } from 'element-plus';
  import { ArrowLeft,ArrowDown,SwitchButton} from '@element-plus/icons';
  import {Logout} from '@/helper/user'

  export default {
    name: "BrandMenu",
    components: {
      ArrowLeft,ArrowDown,SwitchButton
    },
    setup() {
      const store = useStore()
      const {proxy} = getCurrentInstance()
      const router = useRouter();
      const isBack = ref(false);
      const nickName = ref("默认用户")

      const pageTitle= ref('')
      
      onMounted(() => {
          isBack.value = !proxy.$route.meta.noBack||false;
          pageTitle.value = proxy.$route.meta.title;
          nickName.value = store.getters.userInfo.LoginName
          
      });
      watchEffect(()=>{
        isBack.value = !proxy.$route.meta.noBack||false;
        pageTitle.value = proxy.$route.meta.title;
      })

    /********************点击事件**********************/
    function backPreAction(){
        if(isBack){
          router.go(-1)
        }
    }

    //退出
    function logoutAction(){
      ElMessageBox.confirm('是否确认登出', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
             LS.clear('userInfo') //退出的时候清空用户个人信息缓存
             Logout().then(()=>{
                router.replace("/");
                ElMessage({
                  type: 'success',
                  message: '登出成功!'
                });
             })
          }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消'
            });
          });
    }

 
      return {
          pageTitle,
          backPreAction,
          isBack,
          logoutAction,
          nickName
      };
    },
  };
</script>
<style lang="scss" scoped>

.logo-title{
    height:60px;
    display: flex;
    align-items: center;
    // background-color: #000;
    color: #BFB28B;
    font-size: 18px;
    font-weight: bold;

    img{
      width: 160px;
      height: 40%;
      margin-right: 10px;
    }
}

.p-el-header{
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  font-size: 12px;
  background: #000;
  border-bottom: 1px solid #eee;
  .page-title{
    // border-left: 4px solid #409EFF;
    font-weight: 700;
    text-indent: .5em;
    font-size: 20px;
    color:#333;
  }
}

.page-title{
    margin-left: 10px;
}

.brand-title{
    width: 100%;
    height: 60px;
    font-size: 26px;
    color: #000;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;
}

.el-main{
    padding-top:0px !important; 
}

.page-main{
    background: #eee;


    .page-wrap{
        padding:0 10px;
        width: 90%;
        margin:auto;
    }
}

.header-icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pointer{
  cursor: pointer;
}

.user-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-style2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.out-btn{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>